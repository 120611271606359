import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/docs/')({
  component: docsPage,
});

function docsPage() {
  return (
    <div className="p-8">
      <h1 className="text-4xl font-extrabold dark:text-white mb-4">Docs</h1>
      <p className="text-base text-gray-900 dark:text-white mb-4">This is the docs page.</p>
      <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
        <li><a href="/docs/embeddable" className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline">Embeddable</a></li>
        <li><a href="/docs/webhooks" className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline">Webhooks</a></li>
        <li><a href="/docs/standardizedMediaInfo" className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline">StandardizedMediaInfo</a></li>
      </ul>
    </div>
  );
}