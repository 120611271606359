import { createFileRoute } from '@tanstack/react-router';
import SyntaxHighlighter from 'react-syntax-highlighter';

export const Route = createFileRoute('/docs/embeddable/')({
  component: EmbeddableDocs,
});

function EmbeddableDocs() {
  return (
    <div className="p-8">
      <h1 className="text-4xl font-extrabold dark:text-white">Embeddable</h1>
      <p className="mb-3 text-gray-500 dark:text-gray-400">The easiest way to link your MAM to browz is an embeddable
        integration.</p>
      <p className="mb-3 text-gray-500 dark:text-gray-400">This means you provide your interface and relay the medias
        metadata to browz, which validates it and passes it on to the cuez suite.</p>
      <p className="mb-3 text-gray-500 dark:text-gray-400">The embeddable integration expects a <a
        className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
        href="https://developer.mozilla.org/en-US/docs/Web/API/MessageEvent"
      >messageEvent</a> from the embedded interface.</p>
      <p className="mb-3 text-gray-500 dark:text-gray-400">This is how we listen to the event:</p>
      <SyntaxHighlighter language="typescript">
        {
          `window.addEventListener('message', listener );
          
function listener(event: MessageEvent) {
  const {action, payload} = event.data;
}
`
        }
      </SyntaxHighlighter>
      <p className="mb-3 text-gray-500 dark:text-gray-400">The action can either be 'loaded' or 'selected'</p>
      <p className="mb-3 text-gray-500 dark:text-gray-400">The 'loaded' action can be thrown when the ui is loaded and
        doesn't require a payload</p>
      <p className="mb-3 text-gray-500 dark:text-gray-400">The 'selected' action is thrown when an item is clicked on in
        the interface. It should contain a payload of <a
          className="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
          href="/docs/standardizedMediaInfo"
        >StandardisedMediaInfo</a></p>
    </div>
  );
}